<template>
  <BaseModal
    name="ModalIntegrationPluga"
    id="ModalIntegrationPluga"
    idModal="ModalIntegrationPluga"
    size="lg"
    title="Integração - Pluga"
    @shown="openModal()"
    @hidden="step = 0"
    :hideFooter="true"
  >
  <div class="content">
    <div class="content-step" :style="stepStyle[0] ? stepStyle[0] : ''">
      <label>1 - Configurações iniciais do "Pluga":</label>
      <p>Na sua ferramenta "Pluga" clique em criar automatização:</p>
      <img src="@/assets/integrations/pluga/passo1.png" alt="Primeiro passo" class="step-picture">
    </div>
    <div class="content-step" :style="stepStyle[1] ? stepStyle[1] : ''">
      <label>2 - Configurações básica da sua ferramenta e do gatilho: </label>
      <p> Escolha a ferramenta de origem dos dados e o gatilho da sua automatização
        e clique em “Continuar”:</p>
      <img src="@/assets/integrations/pluga/passo2.png" alt="Segundo passo" class="step-picture">
    </div>
    <div class="content-step" :style="stepStyle[2] ? stepStyle[2] : ''">
      <label>3 - Conecte a conta da sua ferramenta</label>
      <img src="@/assets/integrations/pluga/passo3.png" alt="Terceiro passo" class="step-picture">
    </div>
    <div class="content-step" :style="stepStyle[3] ? stepStyle[3] : ''">
      <label>4 - Defina quando as informações da sua ferramenta serão enviadas para a Pluga</label>
      <p>Você deve copiar a URL do Webhook e configurá-la na sua ferramenta. Caso você não saiba como fazer isso, clique em “Veja como adicionar a URL da Pluga na sua ferramenta”:</p>
      <img src="@/assets/integrations/pluga/passo4.png" alt="Quarto passo" class="step-picture">
    </div>
    <div class="content-step" :style="stepStyle[4] ? stepStyle[4] : ''">
      <label>5 - Pronto, agora a sua ferramenta já está conectada e você pode seguir para a configuração da Greenn Sales clicando em “Conectar nova ferramenta”:</label>
      <img src="@/assets/integrations/pluga/passo5.png" alt="Quinto passo" >
    </div>
    <div class="content-step" :style="stepStyle[5] ? stepStyle[5] : ''">
      <label>6 - Selecione o “Pluga Webhooks”, que é a ferramenta de destino</label>
      <p>E a ação é “Enviar uma mensagem via Pluga Webhooks”, depois clique em continuar:</p>
      <img src="@/assets/integrations/pluga/passo6.png" alt="Sexto passo" class="step-picture">
    </div>
    <div class="content-step" :style="stepStyle[6] ? stepStyle[6] : ''">
      <label>7 - Configurações para  "Pluga Webhooks”</label>
      <img src="@/assets/integrations/pluga/passo7-1.png" alt="Sétimo passo" class="step-picture">
      <img src="@/assets/integrations/pluga/passo7-2.png" alt="Sétimo passo" class="step-picture">
    </div>
    <div class="content-step" :style="stepStyle[7] ? stepStyle[7] : ''">
      <label>8 - Ajustes do "Pluga Webhooks" </label>
      <p>Preencha com o nome da ferramenta e a URL da Greenn Sales que deve receber esses dados.
            A URL que você deve inserir nesse campo é:</p>
      <div class="url-code">
        <code id="html-code">{{ returURL }}</code>
        <div class="copy-tag ok" @click="copyTextArea(returURL)">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 256 256">
            <path d="M200,32H163.74a47.92,47.92,0,0,0-71.48,0H56A16,16,0,0,0,40,48V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V48A16,16,0,0,0,200,32Zm-72,0a32,32,0,0,1,32,32H96A32,32,0,0,1,128,32Zm72,184H56V48H82.75A47.93,47.93,0,0,0,80,64v8a8,8,0,0,0,8,8h80a8,8,0,0,0,8-8V64a47.93,47.93,0,0,0-2.75-16H200Z"></path>
          </svg>
          Copiar URL
        </div>
      </div>
      <img src="@/assets/integrations/pluga/passo8.png" alt="Oitavo passo" class="step-picture" style="width: calc(100% - 20px); border: 1px solid var(--white-medium);">
    </div>
    <div class="content-step" :style="stepStyle[8] ? stepStyle[8] : ''">
      <label>9 - Configurações das variáves</label>
      <p>Para que ocorra o cadastro do lead com o nome, e-mail e telefone, configure as variáveis "name", "email" e "phone" e 'chave'. É importante que tenha ao menos o telefone ou o e-mail para que a integração seja bem-sucedida. Outras variáveis serão identificadas como dados adicionais do lead. No campo “Chave” você vai inserir a chave desse campo e no campo “Valor” vai selecionar a informação da sua ferramenta que será enviada. 
      </p>
      <p>- Chave: name (Nome)</p>
      <img src="@/assets/integrations/pluga/passo9-1.png" alt="Nono passo" class="step-picture" style="width: calc(100% - 20px); border: 1px solid var(--white-medium);">
      <p>- Chave: phone (Telefone)</p>
      <img src="@/assets/integrations/pluga/passo9-3.png" alt="Nono passo" class="step-picture" style="width: calc(100% - 20px); border: 1px solid var(--white-medium);">
      <p>- Chave: email (E-mail)</p>
      <img src="@/assets/integrations/pluga/passo9-2.png" alt="Nono passo" class="step-picture" style="width: calc(100% - 20px); border: 1px solid var(--white-medium);">
      <p>- Chave: origin (Origem)</p>
      <img src="@/assets/integrations/pluga/passo9-4.png" alt="Nono passo" class="step-picture" style="width: calc(100% - 20px); border: 1px solid var(--white-medium);">
      <p>- Chave: form_id (Formulário)</p>
      <img src="@/assets/integrations/pluga/passo9-5.png" alt="Nono passo" class="step-picture" style="width: calc(100% - 20px); border: 1px solid var(--white-medium);">
    </div>
    <div class="content-step" :style="stepStyle[9] ? stepStyle[9] : ''">
      <label>10 - Configure seu cabeçalho</label>
      <p>Copie o token abaixo e cole-o no campo "authorization" em "cabeçalho"</p>
      <div class="url-code">
          <code id="html-code">{{ globalToken }}</code>
          <div class="copy-tag ok" @click="copyTextArea(globalToken)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 256 256">
              <path d="M200,32H163.74a47.92,47.92,0,0,0-71.48,0H56A16,16,0,0,0,40,48V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V48A16,16,0,0,0,200,32Zm-72,0a32,32,0,0,1,32,32H96A32,32,0,0,1,128,32Zm72,184H56V48H82.75A47.93,47.93,0,0,0,80,64v8a8,8,0,0,0,8,8h80a8,8,0,0,0,8-8V64a47.93,47.93,0,0,0-2.75-16H200Z"></path>
            </svg>
            Copiar URL
          </div>
      </div>
      <img src="@/assets/integrations/pluga/passo10.png" alt="Décimo passo" class="step-picture" style="width: calc(100% - 20px); border: 1px solid var(--white-medium);">
    </div>
    <div class="content-step" :style="stepStyle[10] ? stepStyle[10] : ''">
      <label>11 - Clique em “Finalizar automatização”  e aproveite a integração!</label>
      <p>Agora, sempre que seus contatos passarem pelo fluxo definido por você na sua ferramenta , eles serão enviados para o Greenn Sales automaticamente!</p>
      <img src="@/assets/integrations/pluga/passo11.png" alt="Décimo primeiro passo" >
    </div>
</div>

  <div class="footer-botton">
    <BaseButton variant="link-info" v-if="step" @click="step--">Voltar</BaseButton>
    <div v-else></div>
    <BaseButton variant="primary" @click="step++" v-if="step < 10">Avançar</BaseButton>
    <BaseButton variant="primary" @click="closeModal()" v-else>Finalizar</BaseButton>
  </div>
  </BaseModal>
</template>

<script>
  import GlobalTokenService from "@/services/resources/GlobalTokenService";
  const serviceGlobalToken = GlobalTokenService.build();

  export default {
    data() {
      return {
        form: false,
        allForms:[],
        loadingForm:false,
        stop: false,
        globalToken:'',
        step: 0,
        returURL: 'https://back.gdigital.com.br/global/register-lead',
      }
    },
    computed: {
      stepStyle() {
        let styles = []
        for (let i = 0; i < this.step + 1; i++) {
          styles.push(i === this.step ? 'transform: translateX(0%);' : 'transform: translateX(-120%);')
        }
        return styles
      }
    },
    methods: {
      openModal(){
        if (!this.globalToken) {
          this.getGlobalToken()
        }
      },
      closeModal() {
        this.$bvModal.hide("ModalIntegrationPluga");
      },
      getGlobalToken() {  
        serviceGlobalToken
          .search()
          .then((resp) => {
            this.globalToken = resp.token;
          });
      },
      copyTextArea(text) {
        navigator.clipboard.writeText(text)
          .then(() => {
            this.$bvToast.toast('URL do formulário foi copiado com sucesso', {
              title: "Copiado",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          });
      },
    },
  }
</script>

<style lang="scss" scoped>
.footer-botton {
  display: flex;
  justify-content: space-between;
}
.step-picture {
  border-radius: 10px;
  width: calc(80% - 15px);
}
.content {
  position: relative;
  width: 100%;
  height: 60vh;
  max-height: 60vh;
  overflow: hidden;
  .content-step {
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 60vh;
    overflow-y: auto;
    transform: translateX(120%);
    transition: .5s;
  }
  label {
    font-size: 16px !important;
  }
  p {
    padding-left: 15px;
  }
  img {
    margin-left: 15px;
    margin-bottom: 20px !important;
  }
}
.url-code {
  background-color: var(--white-light);
  padding: 20px;
  border-radius: 10px 0 10px 10px;
  max-height: 200px;
  position: relative;
  color: var(--gray02);
  padding: 14px 15px;
  min-height: 50px;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
  margin-left: 15px;
  margin-top: 30px;
  code {
    width: 100%;
    color: var(--gray02);
    word-break: break-all;
  }
}
.copy-tag {
  height: 30px;
  font-size: 13px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  padding: 0 10px;
  border-radius: 10px 10px 0 0;
  position: absolute;
  top: -30px;
  right: 0;
  transition: .3s;
}
.ok{
  color: var(--greenn);
  background-color: var(--greenn-transparent);
  cursor: pointer;
  &:hover {
    background-color: var(--greenn2)
  }
  >svg {
    fill: var(--greenn);
  }
}
</style>