<template>
  <BaseModal
    name="ModalIntegrationElementor"
    id="ModalIntegrationElementor"
    idModal="ModalIntegrationElementor"
    size="lg"
    title="Integração - Elementor"
    @shown="openModal()"
    @hidden="step = 0"
    :hideFooter="true"
  >
  <div class="content">
    <div class="content-step" :style="stepStyle[0] ? stepStyle[0] : ''">
      <label>1 - Configure os campos do seu formulaŕio do Elementor</label>
      <p>Na aba "avançado" dos campos do formulário, você encontrará a configuração de "ID". Para que ocorra o cadastro do lead com o nome, e-mail e telefone, configure os IDs dos campos como "name", "email" e "phone". É importante que tenha ao menos o telefone ou o e-mail para que a integração seja bem-sucedida. Outras variáveis serão identificadas como dados adicionais do lead.</p>
      <img src="@/assets/integrations/elementor/step1.jpeg" alt="Primeiro passo" class="step-picture" style="width: 40%; border: 1px solid var(--white-medium);">
    </div>
    <div class="content-step" :style="stepStyle[1] ? stepStyle[1] : ''">
      <label>2 - Adicione a opção de webhook ao formulário do Elementor</label>
      <p>Em "Ações após submissão" (ou "Actions after submit") nas configurações do seu componente, adicione a opção "Webhook".</p>
      <img src="@/assets/integrations/elementor/step2.jpeg" alt="Primeiro passo" class="step-picture" style="width: 40%; border: 1px solid var(--white-medium);">
    </div>
    <div class="content-step" :style="stepStyle[2] ? stepStyle[2] : ''">
      <label>3 - Conecte a um formulário da {{ $store.getters["getNameSystem"] }} com uma URL</label>
      <p>Selecione um formulaŕio da {{ $store.getters["getNameSystem"] }} para gerar uma URL referente a ele. </p>
      <div style="margin-left: 15px; width: calc(100% - 15px); margin-top: 30px">
        <BaseSelect 
          id="formulario"
          :selectModel="form"
          style="margin-top: -20px !important"
          track-by="id"
          :watch="true"
          :array="allForms"
          :multiple="false"
          :searchable="true"
          :taggable="true"
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          :loading="loadingForm"
          @callback="getForms($event)"
          @change="form = $event"
          ><span slot="noResult">Nenhum formulário encontrado.</span>
        </BaseSelect>
        <p style="padding-left: 0;" class="mt-3">URL para se conectar ao formulário:</p>
        <div class="url-code">
          <code id="html-code">{{ returURL }}</code>
          <div class="copy-tag" :class="`${returURL ? 'ok' : 'not'}`" @click="returURL ? copyTextArea() : ''">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 256 256"><path d="M200,32H163.74a47.92,47.92,0,0,0-71.48,0H56A16,16,0,0,0,40,48V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V48A16,16,0,0,0,200,32Zm-72,0a32,32,0,0,1,32,32H96A32,32,0,0,1,128,32Zm72,184H56V48H82.75A47.93,47.93,0,0,0,80,64v8a8,8,0,0,0,8,8h80a8,8,0,0,0,8-8V64a47.93,47.93,0,0,0-2.75-16H200Z"></path></svg>
            Copiar URL
          </div>
        </div>
      </div>
      <p class="mt-3">Adicione essa URL no sessão de webhook do componente do elementor.</p>
      <img src="@/assets/integrations/elementor/step3.jpeg" alt="Primeiro passo" class="step-picture" style="width: 40%; border: 1px solid var(--white-medium);">
    </div>
    <div class="content-step" :style="stepStyle[3] ? stepStyle[3] : ''">
      <label>4 - Ative o envio de dados avançados</label>
      <p>Por fim, permita o envio de dados avançados ao selecionar a opção abaixo do campo onde a URL do webhook foi adicionada. Após isso, salve sua página e aproveite sua integração!</p>
      <img src="@/assets/integrations/elementor/step4.jpeg" alt="Primeiro passo" class="step-picture" style="width: 40%; border: 1px solid var(--white-medium);">
    </div>
  </div>
  <div class="footer-botton">
    <BaseButton variant="link-info" v-if="step" @click="step--">Voltar</BaseButton>
    <div v-else></div>
    <BaseButton variant="primary" @click="step++" v-if="step < 3">Avançar</BaseButton>
    <BaseButton variant="primary" @click="closeModal()" v-else>Finalizar</BaseButton>
  </div>
  </BaseModal>
</template>

<script>
  import FormService from "@/services/resources/FormService";
  const serviceForm = FormService.build();
  //
  import GlobalTokenService from "@/services/resources/GlobalTokenService";
  const serviceGlobalToken = GlobalTokenService.build();

  export default {
    data() {
      return {
        form: false,
        allForms:[],
        loadingForm:false,
        stop: false,
        globalToken:'',
        step: 0,
      }
    },
    computed: {
      returURL() {
        return this.form ? `https://back.gdigital.com.br/integration/elementor?token=${this.globalToken}&form_id=${this.form.id}` : ''
      },
      stepStyle() {
        let styles = []
        for (let i = 0; i < this.step + 1; i++) {
          styles.push(i === this.step ? 'transform: translateX(0%);' : 'transform: translateX(-120%);')
        }
        return styles
      }
    },
    methods: {
      redirectionElementor(){
        window.open("https://Elementor.io/", '_blank')
      },
      getGlobalToken() {  
        serviceGlobalToken
          .search()
          .then((resp) => {
            this.globalToken = resp.token;
          });
      },
      openModal(){
        if (!this.globalToken) {
          this.getGlobalToken()
        }
      },
      getForms(page = 1) {
        console.log('getForms');
        if (this.stop) {
        return 
        }
        var data = {
          page: page,
          status: "active",
        };
        this.loadingForm = true
        serviceForm.search(data).then((resp) => {
          if (resp.data.length < resp.per_page) {
            this.stop = true;
          }
          this.allForms = this.allForms.concat(resp.data);
        }).finally(()=>{
          this.loadingForm = false
        });
      },
      copyTextArea() {
        navigator.clipboard.writeText(this.returURL)
          .then(() => {
            this.$bvToast.toast('URL do formulário foi copiado com sucesso', {
              title: "Copiado",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          });
      },
      closeModal() {
        this.$bvModal.hide("ModalIntegrationElementor");
      },
    },
  }
</script>

<style lang="scss" scoped>
// .content:not(:has(.content-step[style*="transform: translateX(120%)"])) .footer-botton:last-child {
//   display: none;
// }
.footer-botton {
  display: flex;
  justify-content: space-between;
}
.step-picture {
  border-radius: 10px;
  width: calc(80% - 15px);
}
.content {
  position: relative;
  width: 100%;
  height: 60vh;
  max-height: 60vh;
  overflow: hidden;
  .content-step {
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 60vh;
    overflow-y: auto;
    transform: translateX(120%);
    transition: .5s;
  }
  label {
    font-size: 16px !important;
  }
  p {
    padding-left: 15px;
  }
  img {
    margin-left: 15px;
  }
}
.color-arrow{
  stroke:var(--bluee);
  width: 20px;
}
.Elementor-button{
  display: flex;
  align-items: center;
  gap: 10px;
}
.select-input{
  width: 100%;
  display: grid;
  gap: 25px;
}
.input-url-integration{
  svg{
    color: var(--gray);
  }
}
.input-url-integration:hover{
  border-color: #ededf0 !important;  
}
.url-code {
  background-color: var(--white-light);
  padding: 20px;
  border-radius: 10px 0 10px 10px;
  max-height: 200px;
  position: relative;
  color: var(--gray02);
  padding: 14px 15px;
  min-height: 50px;
  align-items: center;
  display: flex;
  code {
    width: 100%;
    color: var(--gray02);
    word-break: break-all;
  }
}
.ok{
  color: var(--greenn);
  background-color: var(--greenn-transparent);
  cursor: pointer;
  &:hover {
    background-color: var(--greenn2)
  }
  >svg {
    fill: var(--greenn);
  }
}
.not{
  color: var(--gray03);
  background-color: var(--white-dark);
  cursor: not-allowed;
  >svg {
    fill: var(--gray03);
  }
}
.copy-tag {
  height: 30px;
  font-size: 13px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  padding: 0 10px;
  border-radius: 10px 10px 0 0;
  position: absolute;
  top: -30px;
  right: 0;
  transition: .3s;
}

</style>